import React from "react";
import ActionMenu from "../../action-menu/ActionMenu";

export const TaskHeaderView = ({ listName, actions }) => {
    return (
        <div className={'todo-header-container header-line'}>
            <h2 className={'todo-header-text'}>{listName}</h2>
            <div className={'action-buttons'}>
                <ActionMenu actions={actions} />
            </div>
        </div>
    );
};