import React, {useState} from "react";
import {faPencil, faTrash} from "@fortawesome/free-solid-svg-icons";
import {faShare} from "@fortawesome/free-solid-svg-icons/faShare";
import {faCheckDouble} from "@fortawesome/free-solid-svg-icons";

import "./TaskListHeader.css";
import { TaskHeaderView } from "./task-header-view/TaskHeaderView";
import { TaskHeaderEdit } from "./task-header-edit/TaskHeaderEdit";

export const TaskListHeader = ({listName, updateListName, shareCollection, onDeleteList, onDeleteCompletedTasks}) => {
    const [isEditing, setIsEditing] = useState(false);
    const [header, setHeader] = useState('');
    const actions = [
        {
            label: "Share Task",
            icon: faShare,
            callback: () => shareCollection(),
        },
        {
            label: "Delete Completed Tasks",
            icon: faCheckDouble,
            callback: () => onDeleteCompletedTasks(),
        },
        {
            label: "Edit List Name",
            icon: faPencil,
            callback: () => handleEditClick(),
        },
        {
            label: "Delete List",
            icon: faTrash,
            callback: () => onDeleteList(),
        },
    ];

    const handleSave = () => {
        if (header.trim()) {
            updateListName(header);
            setIsEditing(false);
            setHeader('');
        }
    };

    const handleEditClick = () => {
        setHeader(listName);
        setIsEditing(true);
    };

    return (
        <div>
            {isEditing
                ? <TaskHeaderEdit 
                    header={header} 
                    setHeader={setHeader} 
                    handleSave={handleSave} 
                  />
                : <TaskHeaderView 
                    listName={listName} 
                    actions={actions} 
                  />
            }
        </div>
    );
};
