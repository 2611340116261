import React, { useState, useRef, useEffect } from "react";
import {
    faPencil,
    faTrash,
    faPlus,
    faGripLines, faTimes,
    faArrowRight,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Button } from "../../../button/Button";
import "./Subtask.css";
import ActionMenu from "../../../action-menu/ActionMenu";
import ListSelectionDialog from "../../../list-selection-dialog/ListSelectionDialog";
import { generateId } from "../../../../utils/Utils";
import { autoResizeTextarea } from "../../../../utils/Utils";
import { ReactSortable } from "react-sortablejs";
import { faChevronDown, faChevronRight } from "@fortawesome/free-solid-svg-icons";

export const Subtask = ({
    subtask,
    toggleComplete,
    deleteSubtask,
    saveSubtask,
    level = 0,
    lists,
    selectedListId,
    moveSubtaskToList,
}) => {
    const [isEditing, setIsEditing] = useState(false);
    const [editedTask, setEditedTask] = useState(subtask.task);
    const [showInput, setShowInput] = useState(false);
    const [subtaskText, setSubtaskText] = useState("");
    const [isExpanded, setIsExpanded] = useState(true);
    const [showListSelectionDialog, setShowListSelectionDialog] = useState(false);
    const editTextareaRef = useRef(null);
    const subtaskTextareaRef = useRef(null);
    const actions = [
        {
            label: "Add Subtask",
            icon: faPlus,
            callback: () => handleShowSubtaskInput(),
        },
        {
            label: "Move to List",
            icon: faArrowRight,
            callback: () => setShowListSelectionDialog(true),
        },
        {
            label: "Edit",
            icon: faPencil,
            callback: () => handleEdit(),
        },
        {
            label: "Delete",
            icon: faTrash,
            callback: () => deleteSubtask(subtask.id),
        },
    ];

    useEffect(() => {
        if (showInput && subtaskTextareaRef.current) {
            subtaskTextareaRef.current.focus();
            autoResizeTextarea({ target: subtaskTextareaRef.current });
        }
    }, [showInput, subtaskText]);

    useEffect(() => {
        if (isEditing && editTextareaRef.current) {
            editTextareaRef.current.focus();
            autoResizeTextarea({ target: editTextareaRef.current });
        }
    }, [isEditing, editedTask]);

    const handleKeyDownEdit = (e) => {
        if (e.key === 'Enter' && e.shiftKey) {
            e.preventDefault();
            setEditedTask(prev => prev + '\n');
        } else if (e.key === 'Enter' && !e.shiftKey) {
            e.preventDefault();
            handleSubmit(e);
        }
    };

    const handleKeyDownSubtask = (e) => {
        if (e.key === 'Enter' && e.shiftKey) {
            e.preventDefault();
            setSubtaskText(prev => prev + '\n');
        } else if (e.key === 'Enter' && !e.shiftKey) {
            e.preventDefault();
            handleSubmitSubtask(e);
        }
    };

    const handleSubmit = (event) => {
        event.preventDefault();
        if (!editedTask) return;

        const editedSubtask = { ...subtask, task: editedTask };
        saveSubtask(editedSubtask);
        setIsEditing(false);
    };

    const handleEdit = () => {
        setEditedTask(subtask.task);
        setIsEditing(true);
    };

    const handleComplete = () => {
        const updatedSubtask = { ...subtask, completed: !subtask.completed };
        toggleComplete(updatedSubtask);
    };

    const handleShowSubtaskInput = () => {
        setShowInput(true);
    };

    const handleAddSubtask = () => {
        if (!subtaskText.trim()) return;

        const newSubtask = {
            id: generateId(),
            task: subtaskText,
            completed: false,
            edit: false,
            subtasks: [],
        };

        const updatedSubtasks = [...(subtask.subtasks || []), newSubtask];
        const updatedSubtask = { ...subtask, subtasks: updatedSubtasks };
        saveSubtask(updatedSubtask);
        setSubtaskText("");
    };

    const handleSubmitSubtask = (e) => {
        e.preventDefault();
        handleAddSubtask();
    };

    const handleCancelSubtask = () => {
        setSubtaskText("");
        setShowInput(false);
    };

    const handleSaveNestedSubtask = (nestedSubtask) => {
        const updatedSubtasks = (subtask.subtasks || []).map((s) =>
            s.id === nestedSubtask.id ? nestedSubtask : s,
        );
        const updatedSubtask = { ...subtask, subtasks: updatedSubtasks };
        saveSubtask(updatedSubtask);
    };

    const handleDeleteNestedSubtask = (nestedSubtaskId) => {
        const updatedSubtasks = (subtask.subtasks || []).filter(
            (s) => s.id !== nestedSubtaskId,
        );
        const updatedSubtask = { ...subtask, subtasks: updatedSubtasks };
        saveSubtask(updatedSubtask);
    };

    const handleCompleteNestedSubtask = (nestedSubtask) => {
        const updatedSubtasks = (subtask.subtasks || []).map((s) =>
            s.id === nestedSubtask.id ? nestedSubtask : s,
        );
        const updatedSubtask = { ...subtask, subtasks: updatedSubtasks };
        saveSubtask(updatedSubtask);
    };

    return (
        <li style={{ marginLeft: `20px` }}>
            {isEditing ? (
                <form className="subtask-container" onSubmit={handleSubmit}>
                    <textarea
                        ref={editTextareaRef}
                        value={editedTask}
                        onChange={(e) => setEditedTask(e.target.value)}
                        onKeyDown={handleKeyDownEdit}
                        placeholder="Edit subtask"
                        rows={1}
                        className="formatted-input"
                    />
                    <Button onClick={handleSubmit} type="primary" text="Save" />
                </form>
            ) : (
                <>
                    <div className={"subtask-container"}>
                        <span className="drag-handle">
                            <FontAwesomeIcon icon={faGripLines} />
                        </span>
                        <div
                            className={`${subtask.completed && "completed"} subtask-text`}
                            onClick={handleComplete}
                        >
                            {subtask.task}
                        </div>
                        <div className={"subtask-buttons-container"}>
                            {subtask.subtasks && subtask.subtasks.length > 0 && (
                                <span onClick={() => setIsExpanded(!isExpanded)} style={{ cursor: 'pointer', padding: '0 5px' }}>
                                    <FontAwesomeIcon icon={isExpanded ? faChevronDown : faChevronRight} className="chevron-icon" />
                                </span>
                            )}
                            <ActionMenu actions={actions}></ActionMenu>
                        </div>
                    </div>
                    
                    {showListSelectionDialog && (
                        <ListSelectionDialog
                            lists={lists}
                            selectedListId={selectedListId}
                            onSelectList={(listId) => {
                                moveSubtaskToList(subtask, listId);
                                setShowListSelectionDialog(false);
                            }}
                            onClose={() => setShowListSelectionDialog(false)}
                        />
                    )}
                </>
            )}

            {showInput && (
                <form
                    className={"new-subtask"}
                    onSubmit={handleSubmitSubtask}
                    style={{ marginLeft: "20px" }}
                >
                    <textarea
                        ref={subtaskTextareaRef}
                        value={subtaskText}
                        onChange={(e) => setSubtaskText(e.target.value)}
                        onKeyDown={handleKeyDownSubtask}
                        placeholder="Add subtask"
                        rows={1}
                        className="formatted-input"
                    />
                    <Button
                        type="submit"
                        status={"primary"}
                        icon={faPlus}
                    ></Button>
                    <Button
                        onClick={handleCancelSubtask}
                        status={"primary"}
                        icon={faTimes}
                    ></Button>
                </form>
            )}

            {subtask.subtasks && subtask.subtasks.length > 0 && isExpanded && (
                <ul className="nested-subtasks">
                    <ReactSortable
                        list={subtask.subtasks}
                        setList={(newSubtasks) => {
                            const updatedSubtask = {
                                ...subtask,
                                subtasks: newSubtasks,
                            };
                            saveSubtask(updatedSubtask);
                        }}
                        handle=".drag-handle"
                    >
                        {subtask.subtasks.map((nestedSubtask) => (
                            <Subtask
                                key={nestedSubtask.id}
                                subtask={nestedSubtask}
                                toggleComplete={handleCompleteNestedSubtask}
                                saveSubtask={handleSaveNestedSubtask}
                                deleteSubtask={handleDeleteNestedSubtask}
                                level={level + 1}
                                lists={lists}
                                selectedListId={selectedListId}
                                moveSubtaskToList={moveSubtaskToList}
                            />
                        ))}
                    </ReactSortable>
                </ul>
            )}
        </li>
    );
};
