export const capitalize = (str) => {
    return str.charAt(0).toUpperCase() + str.slice(1);
}

export const generateId = () => {
    return Date.now();
}

export const filterSubtasks = (subtasks, filter) => {
    return subtasks.filter((subtask) => subtask.task.toLowerCase().includes(filter.toLowerCase()));
}

export const filterTodos = (todos, filter) => {
    return todos.filter((todo) => todo.task.toLowerCase().includes(filter.toLowerCase()));
}

export const autoResizeTextarea = (event) => {
    const textArea = event.target;
    const originalOverflow = textArea.style.overflow;
    const minHeight = parseFloat(getComputedStyle(textArea).minHeight);
    const maxHeight = parseFloat(getComputedStyle(textArea).maxHeight);
    const lineHeight = 24;

    let height;
    if (textArea.value.includes('\n')) {
        const lineCount = textArea.value.split('\n').length;
        height = Math.min(lineCount * lineHeight, maxHeight);
    } else {
        height = Math.max(lineHeight, minHeight);
    }

    textArea.style.height = `${height}px`;
    textArea.style.overflow = originalOverflow;
}

