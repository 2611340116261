import React, {useState, useEffect, useRef} from 'react';
import {faPlus} from "@fortawesome/free-solid-svg-icons";

import './AddTask.css'
import {Button} from "../button/Button";
import {generateId, autoResizeTextarea} from "../../utils/Utils";

export const AddTask = ({addTask}) => {
    const [value, setValue] = useState('');
    const textareaRef = useRef(null);
    
    useEffect(() => {
        if (textareaRef.current) {
            autoResizeTextarea({ target: textareaRef.current });
        }
    }, [value]);

    const handleKeyDown = (e) => {
        if (e.key === 'Enter' && e.shiftKey) {
            e.preventDefault();
            setValue(prev => prev + '\n');
        } else if (e.key === 'Enter' && !e.shiftKey) {
            e.preventDefault();
            handleSubmit(e);
        }
    };
    
    const handleSubmit = e => {
        e.preventDefault();

        if(!value) {
            return;
        }

        handleAddTask(value);
        setValue('');
    }

    const handleAddTask = (task) => {
        const newTask = {
            id: generateId(),
            task,
            completed: false,
            edit: false,
        };

        addTask(newTask);
    };

    return (
        <form onSubmit={handleSubmit} className={'todo-add-step-container'}>
            <textarea 
                ref={textareaRef}
                placeholder='Add something...'
                value={value}
                onChange={(e) => setValue(e.target.value)}
                onKeyDown={handleKeyDown}
                rows={1}
                className="formatted-input"
            />
            <Button
                onClick={handleSubmit}
                status={'primary'}
                icon={faPlus}
            />
        </form>)
}
