import React, {useState, useEffect, useRef} from 'react';
import {Button} from "../../button/Button";
import {autoResizeTextarea} from "../../../utils/Utils";

export const EditTask = ({saveTodo, task}) => {
    const [value, setValue] = useState(task.task);
    const textareaRef = useRef(null);
    
    useEffect(() => {
        if (textareaRef.current) {
            autoResizeTextarea({ target: textareaRef.current });
        }
    }, [value]);

    const handleKeyDown = (e) => {
        if (e.key === 'Enter' && e.shiftKey) {
            e.preventDefault();
            setValue(prev => prev + '\n');
        } else if (e.key === 'Enter' && !e.shiftKey) {
            e.preventDefault();
            handleSubmit(e);
        }
    };
    
    const handleSubmit = e => {
        e.preventDefault();

        if(!value) {
            return;
        }

        saveTodo(value, task.id);
        setValue('');
    }

    return (
        <form onSubmit={handleSubmit} className={'todo-add-step-container'}>
            <textarea 
                ref={textareaRef}
                placeholder='Add a step'
                value={value}
                onChange={(e) => setValue(e.target.value)}
                onKeyDown={handleKeyDown}
                rows={1}
                className="formatted-input"
            />
            <Button
                onClick={handleSubmit}
                type={'primary'}
                text={'Save'}
            />
        </form>)
}
