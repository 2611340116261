import React, { useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faBars } from "@fortawesome/free-solid-svg-icons";

import "./NavigationMenu.css";
import { ListMenu } from "../list-menu/ListMenu";
import { MenuTitle } from "./menu-title/MenuTitle";

export const NavigationMenu = ({
    lists,
    setLists,
    selectedListId,
    onAddList,
    onSelectList
}) => {
    const [showListMenu, setShowListMenu] = useState(false);

    return (
        <>
            <div className="navigation-menu-container">
                <div className="navigation-menu">
                    <MenuTitle title="EasyDone" />
                    <FontAwesomeIcon
                        onClick={() => setShowListMenu(true)}
                        className="menu-icon"
                        icon={faBars}
                    />
                </div>
            </div>
            {showListMenu && (
                <ListMenu
                    lists={lists}
                    setLists={setLists}
                    setShowListMenu={setShowListMenu}
                    selectedListId={selectedListId}
                    onAddList={onAddList}
                    onSelectList={onSelectList}
                />
            )}
        </>
    );
};
