import React, {useEffect} from "react";
import { TaskList } from "../task-list/TaskList";
import { AddTask } from "../add-task/AddTask";
import { TaskListHeader } from "../task-list-header/TaskListHeader";
import "./Dashboard.css";
import { generateId } from "../../utils/Utils";

export const Dashboard = ({ lists, selectedListId, setLists, onDeleteList }) => {
    const selectedList = lists.find((list) => list.id === selectedListId) || {};

    // Save lists to localStorage whenever the lists state changes
    useEffect(() => {
        localStorage.setItem("lists", JSON.stringify(lists));
    }, [lists]);

    // Save selectedListId to localStorage whenever it changes
    useEffect(() => {
        localStorage.setItem("selectedListId", JSON.stringify(selectedListId));
    }, [selectedListId]);

    const updateTodosForSelectedList = (updatedTodos) => {
        setLists(
            lists.map((list) =>
                list.id === selectedListId
                    ? { ...list, todos: updatedTodos }
                    : list,
            ),
        );
    };

    const updateListName = (newName) => {
        setLists(
            lists.map((list) =>
                list.id === selectedListId ? { ...list, name: newName } : list,
            ),
        );
    };

    const handleAddTodo = (task) => {
        const updatedTodos = [...selectedList.todos, task];
        updateTodosForSelectedList(updatedTodos);
    };

    const handleDeleteTodo = (id) => {
        const updatedTodos = selectedList.todos.filter(
            (todo) => todo.id !== id,
        );
        updateTodosForSelectedList(updatedTodos);
    };

    const handleDeleteCompletedTodos = () => {
        const filterCompletedTodos = (todos) => {
            return todos.map(todo => {
                // Create a new todo object with filtered subtasks
                const newTodo = { ...todo };
                if (todo.subtasks && todo.subtasks.length > 0) {
                    newTodo.subtasks = filterCompletedTodos(todo.subtasks);
                }
                return newTodo;
            }).filter(todo => !todo.completed);
        };

        const updatedTodos = filterCompletedTodos(selectedList.todos || []);
        updateTodosForSelectedList(updatedTodos);
    };

    const handleEditTodo = (id) => {
        const updatedTodos = selectedList.todos.map((todo) =>
            todo.id === id ? { ...todo, edit: !todo.edit } : todo,
        );
        updateTodosForSelectedList(updatedTodos);
    };

    const handleUpdateTodo = (updatedTodo) => {
        const updatedTodos = selectedList.todos.map((todo) =>
            todo.id === updatedTodo.id ? updatedTodo : todo,
        );
        updateTodosForSelectedList(updatedTodos);
    };

    const handleSaveTodo = (task, id) => {
        const updatedTodos = selectedList.todos.map((todo) =>
            todo.id === id ? { ...todo, task, edit: false } : todo,
        );
        updateTodosForSelectedList(updatedTodos);
    };
    
    const handleMoveTaskToList = (task, targetListId) => {
        // Create a deep copy of the current lists to work with
        const updatedLists = JSON.parse(JSON.stringify(lists));
        
        // Find the source list and remove the task
        const sourceList = updatedLists.find(list => list.id === selectedListId);
        if (sourceList) {
            sourceList.todos = sourceList.todos.filter(todo => todo.id !== task.id);
        }
        
        // Find the target list and add the task
        const targetList = updatedLists.find(list => list.id === targetListId);
        if (targetList) {
            targetList.todos = [...targetList.todos, task];
        }
        
        // Update the lists state with both changes at once
        setLists(updatedLists);
    };
    
    const handleMoveSubtaskToList = (subtask, targetListId) => {
        // Create a deep copy of the current lists to work with
        const updatedLists = JSON.parse(JSON.stringify(lists));
        
        // Function to recursively find and remove a subtask from a task or another subtask
        const findAndRemoveSubtask = (items) => {
            for (let i = 0; i < items.length; i++) {
                const item = items[i];
                
                // Check if this item has the subtask we're looking for
                if (item.subtasks && item.subtasks.length > 0) {
                    // Check if the subtask is directly in this item's subtasks
                    const subtaskIndex = item.subtasks.findIndex(s => s.id === subtask.id);
                    
                    if (subtaskIndex !== -1) {
                        // Found the subtask, remove it from the array
                        item.subtasks.splice(subtaskIndex, 1);
                        return true; // Indicate that we found and removed the subtask
                    }
                    
                    // If not found directly, search in nested subtasks
                    if (findAndRemoveSubtask(item.subtasks)) {
                        return true; // Propagate the success up the recursion chain
                    }
                }
            }
            return false; // Subtask not found in this branch
        };
        
        // Find the source list and remove the subtask
        const sourceList = updatedLists.find(list => list.id === selectedListId);
        if (sourceList) {
            findAndRemoveSubtask(sourceList.todos);
        }
        
        // Find the target list and add the subtask as a new task
        const targetList = updatedLists.find(list => list.id === targetListId);
        if (targetList) {
            // Convert subtask to a top-level task
            const newTask = {
                id: generateId(),
                task: subtask.task,
                completed: subtask.completed,
                edit: false,
                subtasks: subtask.subtasks || []
            };
            
            targetList.todos.push(newTask);
        }
        
        // Update the lists state with both changes at once
        setLists(updatedLists);
    };

    const shareCollection = () => {
        const dataToShare = {...selectedList, shared: true};
        const jsonString = JSON.stringify(dataToShare);
        const utf8Bytes = new TextEncoder().encode(jsonString);
        const encodedData = btoa(String.fromCharCode(...utf8Bytes));
        const shareLink = `${window.location.origin}/share?data=${encodedData}`;

        if (navigator.share) {
            navigator
                .share({
                    title: "EasyDone Collection",
                    text: "Check out this collection on EasyDone!",
                    url: shareLink,
                })
                .catch((error) => console.error("Sharing failed:", error));
        } else {
            // Fallback: Copy the link to clipboard
            navigator.clipboard.writeText(shareLink).then(() => {
                alert("Shareable link copied to clipboard!");
            });
        }
    };

    return (
        <div className="home-screen">
            {selectedListId ? (
                <div className="task-container">
                    <TaskListHeader
                        listName={selectedList.name}
                        onDeleteList={() => onDeleteList(selectedListId)}
                        updateListName={updateListName}
                        shareCollection={shareCollection}
                        onDeleteCompletedTasks={handleDeleteCompletedTodos}
                    />
                    <div className="todo-list-container">
                        <AddTask addTask={handleAddTodo} />
                        <TaskList
                            todos={selectedList.todos || []}
                            setTodos={updateTodosForSelectedList}
                            deleteTodo={handleDeleteTodo}
                            editTodo={handleEditTodo}
                            updateTodo={handleUpdateTodo}
                            saveTodo={handleSaveTodo}
                            lists={lists}
                            selectedListId={selectedListId}
                            moveTaskToList={handleMoveTaskToList}
                            moveSubtaskToList={handleMoveSubtaskToList}
                        />
                    </div>
                </div>
            ) : (
                <div>Select or create a list to get started!</div>
            )}
        </div>
    );
};
