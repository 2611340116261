import React from "react";
import {Button} from "../../button/Button";

export const TaskHeaderEdit = ({ header, setHeader, handleSave }) => {
    return (
        <form
            onSubmit={handleSave}
            className={'todo-header-container'}
        >
            <input
                placeholder="Enter list name"
                type="text"
                value={header}
                onChange={(e) => setHeader(e.target.value)}
            />
            <Button onClick={handleSave} text="Save" type="primary"/>
        </form>
    );
};