import React, { useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPlus } from "@fortawesome/free-solid-svg-icons";

export const AddList = ({ onAddList, setShowListMenu }) => {
    const [newListName, setNewListName] = useState('');

    const handleAddList = (e) => {
        e.preventDefault();
        if (newListName.trim()) {
            onAddList(newListName.trim());
            setNewListName('');
            setShowListMenu(false);
        }
    };

    return (
        <form onSubmit={handleAddList} className="list-menu-item add-list">
            <input
                type="text"
                placeholder="New List Name"
                value={newListName}
                onChange={(e) => setNewListName(e.target.value)}
            />
            <button type="submit"><FontAwesomeIcon icon={faPlus}/></button>
        </form>
    );
};