import React from 'react';
import './ListSelectionDialog.css';

const ListSelectionDialog = ({ lists, selectedListId, onSelectList, onClose }) => {
    return (
        <div className="list-selection-overlay">
            <div className="list-selection-dialog">
                <div className="list-selection-header">
                    <h3>Move to List</h3>
                    <button className="close-button" onClick={onClose}>×</button>
                </div>
                <div className="list-selection-content">
                    {!lists || lists.length <= 1 ? (
                        <p>No lists available</p>
                    ) : (
                        <ul className="list-selection-items">
                            {lists
                                .filter(list => list.id !== selectedListId)
                                .map(list => (
                                    <li 
                                        key={list.id} 
                                        className="list-selection-item"
                                        onClick={() => {
                                            onSelectList(list.id);
                                            onClose();
                                        }}
                                    >
                                        {list.name}
                                    </li>
                                ))}
                        </ul>
                    )}
                </div>
            </div>
        </div>
    );
};

export default ListSelectionDialog;