import React, { useState, useEffect } from "react";
import { useRef } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
    faGripLines,
    faPencil,
    faPlus,
    faTrash,
    faChevronDown,
    faChevronRight, faTimes,
    faArrowRight,
} from "@fortawesome/free-solid-svg-icons";
import { ReactSortable } from "react-sortablejs";

import "./Task.css";
import { Subtask } from "./subtask/Subtask";
import { Button } from "../../button/Button"; // Import a specific icon
import { generateId } from "../../../utils/Utils";
import ActionMenu from "../../action-menu/ActionMenu";
import {faEllipsis} from "@fortawesome/free-solid-svg-icons/faEllipsis";
import ListSelectionDialog from "../../list-selection-dialog/ListSelectionDialog";
import { autoResizeTextarea } from "../../../utils/Utils";

export const Task = ({
    todo,
    toggleComplete,
    deleteTodo,
    editTodo,
    updateTodo,
    subtasks,
    lists,
    selectedListId,
    moveTaskToList,
    moveSubtaskToList,
}) => {
    const [showInput, setShowInput] = useState(false);
    const [subtaskText, setSubtaskText] = useState("");
    const [isExpanded, setIsExpanded] = useState(true);
    const [showListSelectionDialog, setShowListSelectionDialog] = useState(false);
    const inputRef = useRef(null);
    useEffect(() => {
        if (showInput && inputRef.current) {
            inputRef.current.focus();
            autoResizeTextarea({ target: inputRef.current });
        }
    }, [showInput, subtaskText]);

    const handleKeyDown = (e) => {
        if (e.key === 'Enter' && e.shiftKey) {
            e.preventDefault();
            setSubtaskText(prev => prev + '\n');
        } else if (e.key === 'Enter' && !e.shiftKey) {
            e.preventDefault();
            handleSubmitSubtask(e);
        }
    };

    const handleShowSubtaskInput = () => {
        setShowInput(true);
    };
    const actions = [
        {
            label: "Add Subtask",
            icon: faPlus,
            callback: () => handleShowSubtaskInput(),
        },
        {
            label: "Move to List",
            icon: faArrowRight,
            callback: () => setShowListSelectionDialog(true),
        },
        {
            label: "Edit Task",
            icon: faPencil,
            callback: () => editTodo(todo.id),
        },
        {
            label: "Delete Task",
            icon: faTrash,
            callback: () => deleteTodo(todo.id),
        },
    ];

    const handleUpdateSubtasks = (updatedSubtasks) => {
        const updatedTodo = { ...todo, subtasks: updatedSubtasks };
        updateTodo(updatedTodo);
    };

    const handleSaveSubtask = (subtask) => {
        const updatedSubtasks = subtasks.map((s) =>
            s.id === subtask.id ? subtask : s,
        );
        handleUpdateSubtasks(updatedSubtasks);
    };

    const handleDeleteSubtask = (todo, subtaskId) => {
        const updatedSubtasks = subtasks.filter(
            (subtask) => subtask.id !== subtaskId,
        );
        handleUpdateSubtasks(updatedSubtasks);
    };

    const handleCompleteSubtask = (subtask) => {
        const updatedSubtasks = subtasks.map((s) =>
            s.id === subtask.id ? subtask : s,
        );
        handleUpdateSubtasks(updatedSubtasks);
    };

    const handleAddSubtask = () => {
        const newSubtask = {
            id: generateId(),
            task: subtaskText,
            completed: false,
            edit: false,
            subtasks: [],
        };

        const updatedSubtasks = [...subtasks, newSubtask];
        const updatedTodo = { ...todo, subtasks: updatedSubtasks };
        updateTodo(updatedTodo);
        setSubtaskText("");
    };

    const handleSubmitSubtask = (e) => {
        e.preventDefault();
        if (!subtaskText) return;
        handleAddSubtask();
        // Keep the input field open after submission
    };

    const handleCancelSubtask = () => {
        setSubtaskText("");
        setShowInput(false);
    };

    return (
        <li className="todo-wrapper">
            <div className={"todo-container"}>
                <div className="todo-left-section">
                    <span className="drag-handle">
                        <FontAwesomeIcon icon={faGripLines} />
                    </span>
                    <div
                        className={`${todo.completed && "completed"} todo-text`}
                        onClick={() => toggleComplete(todo.id)}
                    >
                        {todo.task}
                    </div>
                </div>
                <div className="todo-right-section">
                    {subtasks && subtasks.length > 0 && (
                        <span onClick={() => setIsExpanded(!isExpanded)} style={{ cursor: 'pointer', padding: '0 5px' }}>
                            <FontAwesomeIcon icon={isExpanded ? faChevronDown : faChevronRight} className="chevron-icon" />
                        </span>
                    )}
                    <ActionMenu actions={actions} icon={faEllipsis} />
                </div>
            </div>
            
            {showListSelectionDialog && (
                <ListSelectionDialog
                    lists={lists}
                    selectedListId={selectedListId}
                    onSelectList={(listId) => {
                        moveTaskToList(todo, listId);
                        setShowListSelectionDialog(false);
                    }}
                    onClose={() => setShowListSelectionDialog(false)}
                />
            )}

            {showInput && (
                <form className={"new-subtask"} onSubmit={handleSubmitSubtask}>
                    <textarea
                        ref={inputRef}
                        value={subtaskText}
                        onChange={(e) => setSubtaskText(e.target.value)}
                        onKeyDown={handleKeyDown}
                        placeholder="Add subtask"
                        rows={1}
                        className="formatted-input"
                    />
                    <Button
                        type="submit"
                        status={"primary"}
                        icon={faPlus}
                    ></Button>
                    <Button
                        onClick={handleCancelSubtask}
                        status={"primary"}
                        icon={faTimes}
                    ></Button>
                </form>
            )}

            {isExpanded && (
                <ul className="subtasks">
                    <ReactSortable
                        list={subtasks}
                        setList={(newSubtasks) => handleUpdateSubtasks(newSubtasks)}
                        handle=".drag-handle"
                    >
                        {subtasks.map((subtask) => (
                            <Subtask
                                key={subtask.id}
                                subtask={subtask}
                                toggleComplete={handleCompleteSubtask}
                                saveSubtask={handleSaveSubtask}
                                deleteSubtask={() =>
                                    handleDeleteSubtask(todo, subtask.id)
                                }
                                lists={lists}
                                selectedListId={selectedListId}
                                moveSubtaskToList={moveSubtaskToList}
                            />
                        ))}
                    </ReactSortable>
                </ul>
            )}
        </li>
    );
};
