import React from "react";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faXmark} from "@fortawesome/free-solid-svg-icons";
import { ReactSortable } from "react-sortablejs";

import "./ListMenu.css";
import { AddList } from "./add-list/AddList";
import { ListMenuItem } from "./list-menu-item/ListMenuItem";

export const ListMenu = ({lists, setShowListMenu, selectedListId, onAddList, onSelectList, setLists}) => {
    const handleSelectList = (listId) => {
      onSelectList(listId);
      setShowListMenu(false);
    }

    return (
        <div className="list-menu">
            <div className="close-menu-icon" onClick={() => setShowListMenu(false)}>
                <FontAwesomeIcon icon={faXmark}/>
            </div>
            <AddList onAddList={onAddList} setShowListMenu={setShowListMenu} />
            <div className="list-menu-container">
                <ReactSortable
                    list={lists}
                    setList={setLists}
                    handle=".list-drag-handle"
                >
                    {lists.map((list) => (
                        <ListMenuItem 
                            key={list.id}
                            list={list}
                            selectedListId={selectedListId}
                            onSelectList={handleSelectList}
                        />
                    ))}
                </ReactSortable>
            </div>
        </div>
    );
};
