import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faGripLines } from "@fortawesome/free-solid-svg-icons";

export const ListMenuItem = ({ list, selectedListId, onSelectList }) => {
    return (
        <div className={`list-menu-item ${selectedListId === list.id ? 'selected' : ''}`}>
            <div className="list-left-section">
                <span className="list-drag-handle">
                    <FontAwesomeIcon icon={faGripLines} />
                </span>
                <span className="list-name" onClick={() => onSelectList(list.id)}>{list.name}</span>
            </div>
            <span>{list?.todos?.length}</span>
        </div>
    );
};